.chat__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: $line-height-base * $font-size-base * 1;
}

.quickChatTrigger {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $warning;
    cursor: pointer;
    position: relative;
}

.quickChatTrigger svg {
    width: 30px;
    height: auto;
    stroke: $primary;
    fill: $warning;
}

.user-badge {
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    display: flex;
    flex-direction: row;
    width: 40px;
    height: 40px;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
}

.chat__footer {
    padding: 10px;
}

.chat-content-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    height: 100%;
}

.chat-content-holder::-webkit-scrollbar {
    width: 5px;
}

.chat-content-holder::-webkit-scrollbar-track {
    background: #f0f1f2;
}

.chat-content-holder::-webkit-scrollbar-thumb {
    background: #c9cdd1;
}

.chat-content-holder::-webkit-scrollbar-thumb:hover {
    background: #c9cdd1;
}

.chat-content-holder .list-group-item + .list-group-item {
    border-top-width: 1px !important;
}

.conversation__item {
    border: none;
    border-bottom: 1px solid #dfe1e3;
    padding: 1.75rem 1.25rem;
}

.conversation__item:hover {
    background-color: #dfe1e3;
    cursor: pointer;
}

.conversation__item__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.badge-brand {
    color: #fff;
    background-color: #263847;
}

.conversation__item__titel {
    color: #263847;
    font-size: 14px;
}

.chat-window {
    background-color: $white;
    padding: 10px;
}

.chat-window .chat__date__title {
    color: #939ca3;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
}

.chat-window .message__container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.chat-window .message__container .date {
    display: none;
    position: absolute;
    bottom: 0;
    right: 35px;
    font-size: 10px;
    font-style: italic;
}

.chat-window .message__container .date.incoming {
    left: 35px;
    right: unset;
}

.chat-window .message__container:hover .date {
    display: block;
}

.chat-window .message {
    background-color: $light;
    border: 1px solid #eee;
    border-radius: 0 5px 5px 5px;
    padding: 8px 15px;
    margin-right: auto;
    max-width: 70%;
}

.chat-window .message.incoming {
    background-color: #263847;
    color: #fff;
    border-radius: 5px 0 5px 5px;
    margin-left: auto;
    margin-right: 0;
}

.chat-window .message p {
    margin-bottom: 0;
    font-size: $font-size-base;
    word-break: break-word;
}

.last-message--mobile > * {
    text-overflow: ellipsis;
    overflow: hidden;
}

.chat-window .form-control {
    border: 1px solid #eee !important;
}
