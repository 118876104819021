.truncate {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-10 {
    font-size: 10px;
}

.text-11 {
    font-size: 11px;
}

.text-12 {
    font-size: 12px;
}

.text-13 {
    font-size: 13px;
}

.text-14 {
    font-size: 14px;
}

.text-15 {
    font-size: 15px;
}

.text-16 {
    font-size: 16px;
}

.text-17 {
    font-size: 17px;
}

.text-18 {
    font-size: 18px;
}

.text-19 {
    font-size: 19px;
}

.text-20 {
    font-size: 20px;
}

.text-21 {
    font-size: 21px;
}

.text-22 {
    font-size: 22px;
}

.text-23 {
    font-size: 23px;
}

.text-24 {
    font-size: 24px;
}

.text-25 {
    font-size: 25px;
}

.text-26 {
    font-size: 26px;
}

.text-27 {
    font-size: 27px;
}

.text-28 {
    font-size: 28px;
}

.text-29 {
    font-size: 29px;
}

.text-30 {
    font-size: 30px;
}

.op-4 {
    opacity: 0.4;
}

.op-5 {
    opacity: 0.5;
}

.op-6 {
    opacity: 0.6;
}

.op-7 {
    opacity: 0.7;
}

.op-8 {
    opacity: 0.8;
}

.op-9 {
    opacity: 0.9;
}

.ff-spectral {
    font-family: "Spectral" !important;
}

.text-slate {
    color: #263847 !important;
}

.text-slate-90 {
    color: #324350 !important;
}

.text-slate-70 {
    color: #475763 !important;
}

.text-slate-60 {
    color: #52616c !important;
}

.text-slate-50 {
    color: #596771 !important;
}

.text-slate-40 {
    color: #737e87 !important;
}

.text-slate-30 {
    color: #939ca3 !important;
}

.text-slate-20 {
    color: #c9cdd1;
}

.text-slate-15 {
    color: #dcdfe2 !important;
}

.text-slate-10 {
    color: #dfe1e3 !important;
}

.text-slate-5 {
    color: #f0f1f2 !important;
}

.text-yellow {
    color: #faff53 !important;
}

.text-sky {
    color: #c2ebff !important;
}

.text-blue {
    color: #d0f0ff !important;
}

.bg-slate {
    background-color: #263847 !important;
}

.bg-slate-90 {
    background-color: #324350 !important;
}

.bg-slate-70 {
    background-color: #475763 !important;
}

.bg-slate-60 {
    background-color: #52616c !important;
}

.bg-slate-50 {
    background-color: #596771 !important;
}

.bg-slate-40 {
    background-color: #737e87 !important;
}

.bg-slate-30 {
    background-color: #939ca3 !important;
}

.bg-slate-20 {
    background-color: #c9cdd1;
}

.bg-slate-15 {
    background-color: #dcdfe2 !important;
}

.bg-slate-10 {
    background-color: #dfe1e3 !important;
}

.bg-slate-5 {
    background-color: #f0f1f2 !important;
}

.bg-yellow {
    background-color: #faff5e !important;
}

.bg-bespoke {
    background-color: #f1faff !important;
}

.bg-sky {
    background-color: #c2ebff !important;
}

.bg-blue {
    background-color: #d0f0ff !important;
}

.alert p {
    margin: 0;
}

.registration h4 {
    margin-bottom: 0;
}

.main-heading {
    font-size: 40px;
}

.sub-heading {
    font-size: 22px;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-60 {
    margin-top: 60px !important;
}

.mt-70 {
    margin-top: 70px !important;
}

.mt-80 {
    margin-top: 80px !important;
}

.mt-90 {
    margin-top: 90px !important;
}

.mt-100 {
    margin-top: 100px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.mb-80 {
    margin-bottom: 80px !important;
}

.mb-90 {
    margin-bottom: 90px !important;
}

.mb-100 {
    margin-bottom: 100px !important;
}

.toggle {
    font-size: 40px;
}

.text-light {
    color: #ddd !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.b-r-sm {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.p-xs {
    padding: 10px;
}

.overview-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
}
