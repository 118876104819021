@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import 'src/assets/css/variables';
@import '~bootstrap/scss/mixins';

@mixin default-input-style {
	font-weight: 400;
	font-size: 14px;
	line-height: 16px !important;
	height: $input-height;
	border-color: $input-border-color;
}
