/* Styled input[type="text"] */
// Search input with loupe icon
@mixin search-text-input {
	background: url('/assets/images/loupe-icon.svg') no-repeat;
	background-position: 12px 50%;
	border: 1px solid #eeeeee;
	border-radius: 8px;
	height: 40px;
	padding-left: 40px;
	width: 100%;

	&::placeholder {
		font-size: 12px;
		line-height: 14px;
	}

	&:focus {
		outline: none;
	}
	&.loading {
		background: url('/assets/svg-loaders/puff.svg') no-repeat;
		background-size: 32px;
		background-position: 3px center;
	}
}
.search-text-input {
	@include search-text-input;
}
// Search input with loupe icon - light
.search-text-input_light {
	@include search-text-input;
	background: url('/assets/images/loupe-icon-light.svg') no-repeat;
	background-color: #50606d;
	background-position: 12px 50%;
	border: none;
	color: #fff;

	&::placeholder {
		color: #81919d;
	}
}

.normal-text-input {
	background: #ffffff;
	border: 1px solid #eee;
	border-radius: 8px;
	display: block;
	color: #263846;
	font-size: 14px;
	line-height: 17px;
	padding: 12px 16px;
	height: auto;
	flex: 1;
	width: 100%;

	&:focus {
		outline: none;
	}
	&--invalid {
		border-color: red;
	}
}

@mixin date-calendar-input {
	display: flex;
	width: 100%;
	height: 40px;
	border: 1px solid #e1e1e1;
	border-radius: 8px;
	padding: 12px;
	background: url('/assets/images/calendar-simple-icon.svg') calc(100% - 10px) center / 16px no-repeat;
	cursor: pointer;

	&:focus {
		outline: unset;
	}
	&::placeholder {
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: #8a8a8a;
	}
}

.date-calendar-input {
	@include date-calendar-input;
}

@mixin input-with-measure-label {
	display: flex;
	background: #ffffff;
	border: 1px solid #e1e1e1;
	border-radius: 8px;
	position: relative;
	align-items: stretch;
	width: 100%;
	max-height: 40px;

	input {
		border: 0;
		border-radius: 8px;
		color: #757575;
		position: relative;
		flex: 1 1 auto;
		min-width: 0;
		font-size: 14px;
		line-height: 17px;
		padding: 12px;
	}

	span.measure-label {
		display: flex;
		align-items: center;
		white-space: nowrap;
		background-color: white;
		color: #757575;
		font-size: 14px;
		line-height: 17px;
		padding: 12px;
		border: 0;
		border-left: 1px solid #e1e1e1;
		border-radius: 0 8px 8px 0;
		text-align: center;
		width: auto;
	}
}

.input-with-measure-label {
	@include input-with-measure-label;
}

.padded-top-gray-label {
	padding-left: 10px !important;
	font-size: 12px !important;
	line-height: 14.25px !important;
	color: #a1a1a1 !important;
	margin-bottom: 3px !important;
}
