.table-responsive .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 1rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    border-radius: 0;
}

/*.form-check-label, .form-check-label a {*/
/*    color: #FFF;*/
/*}*/

.col-form-label {
    padding-top: calc(0.875rem + 1px);
    padding-bottom: calc(0.875rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}
.col-form-label div {
    font-size: 12px;
}
.checkbox input[type="checkbox"] {
    opacity: 0;
}

.checkbox label {
    position: relative;
    display: inline-block;
    padding-left: 35px;
    line-height: 24px;
}

.checkbox label::before,
.checkbox label::after {
    position: absolute;
    content: "";
    display: inline-block;
}

/*Outer box of the fake checkbox*/
.checkbox label::before {
    height: 20px;
    width: 20px;
    border: 1px solid #d7ae78;
    left: 0px;
    top: 2px;
}

/*Checkmark of the fake checkbox*/
.checkbox label::after {
    height: 6px;
    width: 12px;
    border-left: 2px solid #d7ae78;
    border-bottom: 2px solid #d7ae78;

    transform: rotate(-45deg);

    left: 4px;
    top: 7px;
}

/*Hide the checkmark by default*/
.checkbox input[type="checkbox"] + label::after {
    content: none;
}

/*Unhide on the checked state*/
.checkbox input[type="checkbox"]:checked + label::after {
    content: "";
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.checkbox input[type="checkbox"]:focus + label::before {
    /*outline: rgb(59, 153, 252) auto 5px;*/
}
.border-danger {
    border-color: red;
}

.ck.ck-editor__main > .ck-editor__editable {
    min-height: 200px;
}
.dropdown-toggle {
    width: 160px;
    text-align: left;
}
.dropdown-toggle-bespoke::after {
    position: absolute;
    top: 46%;
    right: 10px;
    margin-left: 0;
}
.input-group > .input-group-append > .btn {
    border: 1px solid #ced4da;
}
