// Fonts
@import url('https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
	font-family: 'grotesk';
	src: url('/assets/fonts/Aktiv Grotesk W06 Regular.ttf') format('truetype');
	font-display: swap;
}

// @font-face {
//     font-family: "grotesk-bold";
//     src: url("/assets/fonts/Aktiv Grotesk W01 Bold.ttf") format("truetype");
//     font-display: swap;
// }

// Configuration
@import 'assets/css/functions';
@import '~src/assets/css/variables';
@import 'assets/css/mixins';
@import '../node_modules/bootstrap/scss/utilities';
@import '~bootstrap/scss/reboot';

// Font Awesome
@import '../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../node_modules/@fortawesome/fontawesome-free/scss/brands';
@import '../node_modules/@fortawesome/fontawesome-free/scss/regular';

// Layout & components
@import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/type';
@import '../node_modules/bootstrap/scss/images';
@import '../node_modules/bootstrap/scss/containers';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/tables';
@import '../node_modules/bootstrap/scss/forms';
@import '../node_modules/bootstrap/scss/buttons';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/dropdown';
@import '../node_modules/bootstrap/scss/button-group';
@import '../node_modules/bootstrap/scss/nav';
@import '../node_modules/bootstrap/scss/navbar';
@import '../node_modules/bootstrap/scss/card';
@import '../node_modules/bootstrap/scss/accordion';
@import '../node_modules/bootstrap/scss/breadcrumb';
@import '../node_modules/bootstrap/scss/pagination';
@import '../node_modules/bootstrap/scss/badge';
@import '../node_modules/bootstrap/scss/alert';
@import '../node_modules/bootstrap/scss/progress';
@import '../node_modules/bootstrap/scss/list-group';
@import '../node_modules/bootstrap/scss/close';
@import '../node_modules/bootstrap/scss/toasts';
@import '../node_modules/bootstrap/scss/modal';
@import '../node_modules/bootstrap/scss/tooltip';
@import '../node_modules/bootstrap/scss/popover';
@import '../node_modules/bootstrap/scss/carousel';
@import '../node_modules/bootstrap/scss/spinners';

// Helpers
@import '../node_modules/bootstrap/scss/helpers';

// Utilities
@import '../node_modules/bootstrap/scss/utilities/api';

// Custom Imports
@import 'assets/css/keen-slider';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'assets/css/dragula';
@import 'assets/css/typography';

@import 'assets/css/framework';
@import 'assets/css/forms';
@import 'assets/css/modal';
@import 'assets/css/sweetalert';
@import 'assets/css/cms';
@import 'assets/css/blog';
@import 'assets/css/buttons';
@import 'assets/css/dropzone';
@import 'assets/css/basket';
@import 'assets/css/products';
@import 'assets/css/user-menu';
@import 'assets/css/aside';
@import 'assets/css/chat';
@import 'assets/css/mail';
@import 'assets/css/stylesheet';
@import 'assets/css/custom-inputs';
@import 'assets/css/input-text';
@import 'assets/css/dropdowns';

html,
body {
	height: 100%;
}

.btn-primary:active {
	background-color: #f8ff2a;
}

.table td,
.table th {
	padding: 1rem 0.5rem !important;
	border: none !important;
}

.table td {
	font-family: 'grotesk', 'sans-serif';
}
