.basket-container {
    padding: 0 5px;
}

.basket-nav {
    display: flex;
    justify-content: space-between !important;
    padding: 30px;
}

.basket-nav h1 {
    line-height: 2.2;
    margin-bottom: 0;
}

.basket-col-container {
    display: flex;
}

.basket-col-left {
    width: 65%;
}

.basket-col-right {
    width: 35%;
}

.basket-col-container .basket-totals {
    padding: 40px;
    height: 100%;
}

.basket-row {
    border-top: 1px solid #ccc;
}

.basket-row-item-detail {
    padding-top: 15px;
}

.total-bar {
    border-top: 1px solid #212529;
}

.billing-image-preview {
    text-align: center;
}

.billing-image-preview img {
    width: 150px;
}

.basket-detail-title {
    font-family: "Spectral" !important;
    color: #ffffff;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    padding-bottom: 30px;
}

.basket-detail-total {
    font-family: "Spectral" !important;
    color: #ffffff;
    font-family: Spectral;
    font-size: 32px;
    line-height: 48.7px;
    text-align: right;
}

.basket-product-name {
    font-family: "Spectral" !important;
    color: #263847 !important;
    text-decoration: none !important;
    font-size: 18px !important;
}

.billing-container {
    border-top: 1px solid #ccc;
    padding: 0;
}

.billing-info-content {
    padding: 15px;
}

.billing-info-title {
    padding: 45px 0;
    border-bottom: 1px solid #ccc;
}

.billing-info-title h1 {
    font-size: 32px;
    font-weight: bold;
}

.billing-info-subtitle {
    font-family: "Spectral" !important;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 25px;
}

.order-info-holder {
    padding: 0 40px 25px 40px;
    background-color: #ffffff;
}

.order-info-title {
    padding: 45px 0;
}

.order-info-title h1 {
    font-size: 32px;
    font-weight: bold;
}

.billing-info-content .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 2;
    font-size: 16px;
}

.payment-cards {
    max-width: 100%;
}

.corner--sample {
    & span {
        right: 10px !important;
        bottom: 10px !important;
    }
}

@include media-breakpoint-up(xl) {
    .corner--sample {
        & span {
            right: 25px !important;
            bottom: 25px !important;
        }
    }
}
