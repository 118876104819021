.keen-slider {
    display: block;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.keen-slider__slide {
    position: absolute;
    overflow: hidden;
    width: 100%;
    min-height: 100%
}

.keen-slider--vertical .keen-slider__slide {
    min-height: auto
}

/*# sourceMappingURL=keen-slider.min.css.map */

