.hover {
    cursor: pointer;
}

.site-container {
    position: relative;
}

.slider-text {
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.slider-text h1 {
    color: white !important;
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
}

.placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #939ca3;
    color: #3d4d59;
    text-align: center;
    background-image: url("https://api.ysprod.co.uk/cms/images/18bf54e10a1f58513a581038e1104fb97a3.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.placeholder h1 {
    font-family: "Spectral" !important;
    color: white !important;
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 40px;
}

.placeholder p {
    font-family: "Spectral" !important;
    color: white !important;
    font-size: 16px;
    line-height: 40px;
    opacity: 1;
}

.op-50 {
    opacity: 0.5;
}

.worked-with-image {
    text-align: center;
}

.worked-with-image img {
    height: 50px;
}

#home-discover {
    height: 244px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#home-discover h3 {
    font-family: "Spectral" !important;
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 30px;
    font-weight: 300;
}

/*Trustpilot slider*/

p.cta {
    line-height: 25px;
    font-size: 21px;
}

.user-account-container {
    padding: 0;
    margin-left: 0;
    width: 100%;
}

.dashboard-icon {
    font-size: 24px;
}

.tile-header {
    font-size: 16px;
    margin-bottom: 15px;
}

.tile-header a {
    font-size: 20px;
}

.dashboard-rewards-slider-value {
    font-size: 11px;
}

#meet-the-team {
    padding: 50px;
}

#meet-the-team h1 {
    font-size: 22px;
}

#meet-the-team .tile {
    text-align: center;
}

#meet-the-team p.name {
    text-align: center;
    font-family: "Spectral" !important;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 5px;
}

#meet-the-team p.job-title {
    text-align: center;
    font-family: "Spectral" !important;
    font-size: 20px;
    opacity: 0.6;
}

.triangle {
    border-left: 40px solid transparent;
    border-right: 40px solid green;
    border-top: 40px solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    right: 0px;
    z-index: 2;
    bottom: 0;
}

.corner {
    $width: 130px;
    $height: 130px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: $width;
    height: $height;
    text-align: center;
    pointer-events: none;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 $width $height;
        border-color: transparent transparent $warning transparent;
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    & span {
        position: absolute;
        right: 10px;
        bottom: 10px;
        z-index: 1;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
    }

    & svg {
        width: 30px;
        height: 30px;
        stroke: #263847;
        margin-bottom: 9px;
    }
}

@include media-breakpoint-up(lg) {
    body {
        padding: 0;
    }
    .data-container {
        background-color: #fff;
        padding: 30px;
    }
    .promoBarOption {
        min-width: 33%;
        font-size: 13px;
    }
    .product-detail-container {
        padding: 10px;
    }
    .user-account-container {
        padding: 50px;
        margin-left: 252px;
        width: calc(100% - 252px);
    }
    .dashboard-reward-points-title {
        font-size: 22px;
    }
    .dashboard-reward-points-value {
        font-size: 45px;
    }
    .dashboard-rewards-slider-value {
        font-size: 14px;
    }
}

@include media-breakpoint-up(lg) {
    .product-detail-container {
        padding: 50px;
    }
    .container-menu {
        padding-right: 15px;
        padding-left: 15px;
    }
    .promoBarOption {
        min-width: 25%;
        font-size: 14px;
    }
    .placeholder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #939ca3;
        color: #3d4d59;
    }
    .placeholder h1 {
        font-family: "Spectral" !important;
        color: white !important;
        font-size: 55px;
        margin-bottom: 40px;
    }
    .placeholder p {
        font-family: "Spectral" !important;
        color: white !important;
        font-size: 20px;
        line-height: 30px;
        opacity: 0.6;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .slider-text {
        width: 715px;
        margin: 0 auto;
        text-align: center;
    }
    .slider-text h1 {
        color: white !important;
        margin-bottom: 32px;
        font-size: 53px;
        line-height: 60px;
        text-align: center;
        letter-spacing: -0.8px;
        font-weight: 300;
    }
    .worked-with-image {
        text-align: center;
    }
    .worked-with-image img {
        height: 50px;
    }
    .bg-image {
        height: 286.25px;
        width: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    p.cta {
        line-height: 25px;
        font-size: 21px;
    }
    #home-discover h3 {
        font-family: "Spectral" !important;
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: 400;
    }
    .product-group-detail {
        text-align: center;
        margin-top: 80px;
        background-color: #fff;
    }
    .product-group-detail h1 {
        font-size: 30px;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .product-group-detail p.group-description {
        font-size: 14px;
        margin-bottom: 50px;
    }
    .product-group-detail p.group-description {
        font-size: 14px;
        margin-bottom: 50px;
    }
    .product-detail-container {
        padding: 50px;
    }
    .product-detail-container h2 {
        font-size: 1.5rem;
    }
    .product-detail-container h1 {
        font-size: 32px;
    }
    .product-detail-container h3 {
        font-size: 12px;
        opacity: 0.5;
    }
    .product-description-holder {
        padding: 35px;
        background-color: #dfe1e3;
    }
    .product-options-container {
        padding: 1.5rem !important;
        background-color: #fff;
    }
    .main-image {
        position: relative;
        height: 650px;
        width: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .basket-container {
        padding: 0 40px;
    }
    .basket-product-name {
        font-family: "Spectral" !important;
        color: #263847 !important;
        text-decoration: none !important;
        font-size: 20px !important;
        font-weight: 500;
    }
    .basket-product-price {
        font-family: "Spectral" !important;
        color: #263847 !important;
        font-size: 20px !important;
        margin-bottom: 0;
    }
    .basket-sub-total {
        font-family: "Spectral" !important;
        color: #263847 !important;
        text-decoration: none !important;
        font-size: 20px !important;
        font-weight: 500;
    }
    .basket-sub-total-price {
        font-family: "Spectral" !important;
        color: #263847 !important;
        text-decoration: none !important;
        font-size: 32px !important;
        font-weight: 500;
    }
}

@include media-breakpoint-up(xl) {
    #product-group-preview .group-preview-container {
        padding: 0 70px 79px 70px;
        background-color: #fff;
    }
    .site-container {
        max-width: 1440px;
        min-height: 800px;
        margin: 0 auto;
        background-color: #fff;
    }
    .billing-container {
        border-top: 1px solid #ccc;
        padding: 0 70px 0 70px;
    }
    .billing-info-content {
        padding-left: 0;
        padding-right: 115px;
        padding-top: 32px;
    }
    .payment-cards {
        max-width: 80%;
    }

    .corner {
        $width: 150px;
        $height: 150px;
        width: $width;
        height: $height;

        &::after {
            border-width: 0 0 $width $height;
        }

        & span {
            right: 15px;
            bottom: 15px;
            font-size: $font-size-base;
        }

        & svg {
            width: 36px;
            height: 36px;
        }
    }

    .corner--small {
        $width: 50px;
        $height: 50px;
        width: $width;
        height: $height;

        &::after {
            border-width: 0 0 $width $height;
        }

        & span {
            right: 3px;
            bottom: 3px;
            font-size: $font-size-base * 0.7 !important;
        }
    }
}
