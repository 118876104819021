.nav-item:hover > .dropdown-toggle-arrow::after {
    background-image: url('/assets/images/icon-arrow-short-down-dark.svg');
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none;
    background-color: #fff;
    color: #475763;
}

.dropdown-menu-option {
    width: 140px;
    display: inline-block;
    margin: 0 50px 30px 0;
    border-bottom: 2px solid #fff;
}

.dropdown-menu-option:nth-last-child(-n+3) {
    margin: 0 50px 0 0;
}

.dropdown-menu-option a.dropdown-item {
    color: #475763 !important;
}

.dropdown-menu-option .dropdown-item {
    border-bottom: 2px solid transparent;
    display: inline-block;
    width: auto;
}

.dropdown-menu-option .dropdown-item:focus,
.dropdown-menu-option .dropdown-item:hover {
    text-decoration: none;
    border-bottom: 2px solid #c2ebff;
    background-color: #fff;
}

.dropdown-toggle-arrow {
    line-height: 34px;
    text-align: center;
}

.dropdown-toggle-arrow::after {
    display: inline-block;
    content: ' ';
    background-image: url('/assets/images/icon-arrow-short-down.svg');
    background-position: center;
    background-size: 10px 10px;
    background-repeat: no-repeat;
    margin-left: 10px;
    width: 10px;
    height: 10px;
}

.dropdown-menu-user {
    margin-top: 9px;
    min-width: 200px;
    border-radius: 0;

    .dropdown-item {
        border-bottom: 2px solid transparent;
    }

    .dropdown-item:hover, .dropdown-item:active, .dropdown-item:visited, .dropdown-item:focus {
        text-decoration: none;
        border-bottom: 2px solid #c2ebff;
        background-color: #fff;
    }
}

.dropdown-menu-notifications {
    margin-top: 9px;
    min-width: calc(100vw - 15px);
    border-radius: 0;
    padding: 0;

    &.dropdown-menu-end {
        right: -55px;
        left: auto;

        @include media-breakpoint-up(md) {
            right: 0;
            left: auto;
        }
    }

    @include media-breakpoint-up(md) {
        min-width: 400px;
    }
}

#header .nav-link.dropdown-toggle {
    line-height: 34px;
    max-width: 90px;
    text-align: center;
}

.dropdown-menu-end {
    right: 0;
    left: auto;
}
