@import "mixins";

/* purgecss start ignore */

.bg-teal {
    background-color: $info;
}

.bg-grey {
    background-color: #F0F1F2;
}

.page-intro {
    position: relative;
    font-family: "Spectral" !important;
    height: 385px;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 20px;
    line-height: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.page-intro h1 {
    color: white !important;
    font-size: 40px;
    font-weight: 300;
}

.page-intro .text-box {
    width: 85%;
    margin: 30px auto;
}

.page-intro .text-box p {
    font-size: 18px;
    font-weight: normal;
}

.container-half {
    width: 50%;
    margin: 0 auto;
}

.container--article {
    max-width: 970px !important;
}

.list__key-facts__title {
    border-bottom: 1px solid #52616C;
}

.list__key-fact {
    border-bottom: 1px solid #52616C;
}

.list__key-fact:last-child {
    border-bottom: none;
}

.cms-content h4 {
    text-align: center;
    font-size: 22px;
    font-family: "Spectral" !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #fff;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    background-color: #68747e;
}

.cms-image-float-left {
    padding: 3px;
    border: 1px solid #eee;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}

.cms-image-float-right {
    padding: 3px;
    border: 1px solid #eee;
    float: right;
    margin-left: 10px;
    margin-bottom: 10px;
}

.px-6 {
    padding: 3.5rem !important;
}

.py-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
}

.px-7 {
    padding: 5.5rem !important;
}

.py-7 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
}

.px-8 {
    padding: 7.5rem !important;
}

.py-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
}

.staff-contact {
    background-image: url("https://api.ysprod.co.uk/cms/images/18bf54e10a1f58513a581038e1104fb97a3.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 55px 80px;
}

.contentColumn {
    width: 100%;
    margin: 0 auto;
}

.grid-row-mobile-reverse {
    flex-direction: column-reverse;
}

.grid-tile {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 410px;
}

.grid-tile h1 {
    font-size: 30px;
}

.grid-tile h2 {
    font-size: 25px;
}

.px-120 {
    padding: 120px !important;
}

.py-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
}

.px-100 {
    padding: 100px !important;
}

.py-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}

@include media-breakpoint-up(sm) {
    .page-intro h1 {
        font-size: 46px;
    }
    .page-intro .text-box {
        width: 65%;
        margin: 30px auto;
    }
    .page-intro .text-box p {
        font-size: 20px;
    }
    .grid-row-mobile-reverse {
        flex-direction: row;
    }
}

@include media-breakpoint-up(md) {
    .page-intro h1 {
        font-size: 56px;
    }
    .page-intro .text-box {
        width: 50%;
        margin: 30px auto;
    }
    .page-intro .text-box p {
        font-size: 20px;
    }
    .grid-row-mobile-reverse {
        flex-direction: row;
    }
}

@include media-breakpoint-up(lg) {
    .page-intro h1 {
        font-size: 56px;
    }
    .page-intro .text-box {
        width: 50%;
        margin: 30px auto;
    }
    .page-intro .text-box p {
        font-size: 20px;
    }
    .grid-row-mobile-reverse {
        flex-direction: row;
    }
}

@include media-breakpoint-up(lg) {
    .page-intro h1 {
        font-size: 56px;
        line-height: 64px;
        margin-bottom: 21px;
    }
    .page-intro .text-box {
        width: 50%;
        margin: 0;
    }
    .page-intro .text-box p {
        font-size: 20px;
        line-height: 24px;
    }
    .contentColumn {
        width: 50%;
        margin: 0 auto;
    }
}

@include media-breakpoint-up(xl) {
    .list__key-fact__title {
        width: 70%;
    }
    .list__key-fact__content {
        width: 30%;
    }
}

/* purgecss end ignore */
