.bg-image {
    position: relative;
    height: 250px;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;
}

.bg-image-contain {
    position: relative;
    height: 250px;
    width: 100%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;
}

.gallery-image {
    height: 650px;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.gallery-image-tile {
    height: 250px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}


/*product types*/

#product-group-preview.home {
    display: none;
}

#product-type-preview .type-preview-container {
    padding: 38px 30px 38px 30px;
}

#product-group-preview .header-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#product-group-preview .group-preview-container {
    padding: 30px;
    background-color: #f0f1f2;
}

#product-group-preview h1 {
    font-family: "Spectral" !important;
    font-size: 20px;
    line-height: 24px;
    margin: 30px 0;
    font-weight: 600;
}

#product-group-preview .group-preview-tile {
    text-align: center;
    margin-bottom: 20px;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    -webkit-filter: blur(0);
}

@include media-breakpoint-up(sm) {
    .product-type-preview-tiles {
        display: block;
    }
    #product-group-preview.home {
        display: none;
    }
    #product-group-preview .group-preview-container {
        padding: 35px 45px 63px 45px;
        background-color: #f0f1f2;
    }
    #product-group-preview h1 {
        font-size: 20px;
    }
    #product-group-preview .group-preview-tile {
        text-align: center;
        margin-bottom: 20px;
    }
}

@include media-breakpoint-up(md) {
    .product-type-preview-tiles {
        display: block;
    }
    #product-group-preview.home {
        display: none;
    }
    #product-type-preview .type-preview-container {
        padding: 55px 40px 0 40px;
    }
}

@include media-breakpoint-up(lg) {
    .product-type-preview-tiles {
        display: none;
    }
    #product-group-preview.home {
        display: block;
    }
    #product-group-preview .header-holder {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

/* extra large */
@include media-breakpoint-up(xl) {
    #product-group-preview h1 {
        font-size: 18px;
        line-height: 22px;
        margin: 40px 0;
    }
    #product-group-preview .group-preview-tile {
        margin-bottom: 0;
    }
}
