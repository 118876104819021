@import '../../../node_modules/bootstrap/scss/variables';

// Colors
$primary: #263847;
$secondary: #3d4d59;
$success: #1ab67a;
$info: #c2ebff;
$warning: #faff5e;
$danger: #d83514;
$light: #f0f1f2;
$gray: #c9cdd1;
$white: #fff;
$black: #161616;

$icon-grey-bg: #6d889b;
$icon-fill-hover: var(--bs-warning);

$custom-theme-colors: (
	'primary': $primary,
	'secondary': $secondary,
	'success': $success,
	'info': $info,
	'warning': $warning,
	'danger': $danger,
	'light': $light,
	'white': $white,
	'gray': $gray,
	'black': $black,
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'text');
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'bg');

// Body
$enable-smooth-scroll: false;
$body-bg: $light;
$body-color: $primary;

// Typography
$font-family-base: 'grotesk', 'sans-serif';
$font-family-bold-base: 'grotesk-bold', 'sans-serif';
$font-size-base: 1rem;
$font-family-base: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans',
	sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-size-base: 1rem; // 16px
$font-size-xxs: $font-size-base * 0.625; // ± 10px
$font-size-xs: $font-size-base * 0.8125; // ± 13px
$font-size-sm: $font-size-base * 0.875; // ± 14px
$font-size-lg: $font-size-base * 1.375; // ± 22px

$headings-font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
	'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$headings-color: $primary;
$h1-font-size: $font-size-base * 2.5; // ± 40px
$h2-font-size: $font-size-base * 2; // ± 32px
$h3-font-size: $font-size-base * 1.75; // ± 28px
$h4-font-size: $font-size-base * 1.5; // ± 24px
$h5-font-size: $font-size-base * 1.25; // ± 20px
$h6-font-size: $font-size-base;
$font-sizes: (
	1: $h1-font-size,
	2: $h2-font-size,
	3: $h3-font-size,
	4: $h4-font-size,
	5: $h5-font-size,
	6: $h6-font-size,
);

// Links
$link-color: $primary;
$link-decoration: none;

// Buttons
$btn-font-size: 13px;
$btn-font-size-md: 13px;
$btn-font-size-xl: 13px;

$btn-border-radius: 30px;
$btn-border-radius-sm: 30px;

$btn-padding-x: 25px;
$btn-padding-y: 13px;
$btn-padding-x-md: 45px;
$btn-padding-y-md: 12px;
$btn-padding-x-xl: 45px;
$btn-padding-y-xl: 17px;

// Forms
$enable-validation-icons: false;
$input-border-color: #eee;
$input-border-active-color: #263846;
$input-focus-border-color: lighten($input-border-active-color, 10%);
$input-focus-box-shadow: inset 0 1px 1px rgba(lighten($input-border-active-color, 10%), 0.075),
	0 0 0 3px rgba(lighten($input-border-active-color, 10%), 0.1);
$input-border-radius: 8px;
$input-height: 40px;
$form-switch-width: 35px;
$form-switch-height: 20px;
$form-check-input-checked-bg-color: $primary;
$form-check-input-checked-border-color: $primary;
$form-check-input-focus-box-shadow: none;
$form-switch-color-rgb: rgba($primary, 0.99);
$form-switch-checked-color-rgb: rgba(#fff, 0.99);
$form-check-input-focus-border: $primary;

// Navs
$nav-pills-link-active-bg: $primary;
$nav-link-font-weight: 700;

// Breakpoints
$grid-breakpoints: (
	xxs: 0,
	xs: 350px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
	xxxl: 1900px,
);

// Containers
$container-max-widths: (
	xxl: 1600px,
);

// Modals
$modal-dialog-margin: 0;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;

// Fontawesome
$fa-font-path: '/assets/fonts';

// Table
$table-cell-padding-y: 1rem;

// Pagination
$pagination-color: $primary;
$pagination-hover-color: $primary;
$pagination-active-color: $white;
$pagination-active-bg: $primary;
$pagination-active-border-color: $primary;
$pagination-border-color: $gray;
$pagination-disabled-border-color: $gray;

$z-index-page: 100; // within a page: flat structure
$z-index-overlay: 2000; // some covering page z-index, f.e. sliding box etc.
$z-index-modal: 3000; // all modal boxes that control flow and require user focus
$z-index-modal: 5000; // system warning, such as errors etc.

// Contextual menu colors
$active_bullet_color: #50e3c2;
$inactive_bullet_color: #bc2718;
$grey_bullet_color: #d1cfc9;
$offline_bullet_color: darken(#d1cfc9, 60%);

// Main elements heights
$header-height: 80px;
$main-screen-wrapper: 66px;

// Smart Search
$smart-search-max-height: 588px;
$smart-search-header-height: 70px;

// Order create process
$order-create-nav-h: 49px;
$order-create-nav-mb: 22px;
$create-order-screen-header-h: 40px;
