.dropzone {
    min-height: 0;
    padding: 0;

    &.is-invalid {
        border: 1px dashed $danger;
    }
}
.dropzone {
    border: 1px dashed #737E87;
    background: #F0F1F2;
    padding: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dropzone .dz-preview .dz-image {
    border-radius: 5px;
    overflow: hidden;
    width: 40px;
    height: 40px;
    position: relative;
    display: block;
    z-index: 10;
}
.dropzone .dz-preview .dz-progress .dz-upload {
    background: #6aba8a;
    background: linear-gradient(to bottom, #6aba8a, #28a745);
}
.dz-success-mark {
    display: none;
}
.dz-error-mark {
    display: none;
}
.dz-details {
    text-transform: lowercase;
    display: none;
}
.dz-complete {
    display: none !important;
}
.dz-message__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    pointer-events: none;
}
.dropzone .dz-message {
    color: #263847;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 32px;
}
.dropzone .dz-btn-cta {
    font-size: 14px;
    line-height: 1;
    padding: 15px 20px;
    width: 225px;
    white-space: nowrap;
}
