@import 'src/assets/css/mixins';

.btn {
	appearance: none;
	-webkit-appearance: none;
	line-height: 1;
	white-space: nowrap;
}

.btn-primary {
	appearance: none;
	-webkit-appearance: none;

	@include button-variant($primary, $primary, $white, darken($primary, 10%), darken($primary, 10%), $white);
	-webkit-text-fill-color: $white;
}

.btn-outline-primary {
	appearance: none;
	-webkit-appearance: none;

	@include button-variant(transparent, $primary, $primary, $primary, $primary, $white);

	&:disabled {
		color: $primary;
	}
}

.btn-secondary {
	appearance: none;
	-webkit-appearance: none;

	@include button-variant($secondary, $secondary, $white, $primary, $primary, $white);
}

.btn-outline-secondary {
	appearance: none;
	-webkit-appearance: none;
	@include button-variant(transparent, $secondary, $secondary, $primary, $primary, $white);
}

.btn-warning {
	appearance: none;
	-webkit-appearance: none;
	@include button-variant($warning, $warning, $primary, $warning, $warning, $primary);
}

.btn-info {
	appearance: none;
	-webkit-appearance: none;
	@include button-variant($info, $info, $primary, $info, $info, $primary);
}

.btn-light {
	appearance: none;
	-webkit-appearance: none;
	@include button-variant($light, $light, $primary, $primary, $primary, $white);
}

.btn-white {
	appearance: none;
	-webkit-appearance: none;
	@include button-variant($white, $white, $primary, lighten($info, 7%), lighten($info, 7%), $primary);
}

.btn-outline-white {
	appearance: none;
	-webkit-appearance: none;
	@include button-variant(transparent, $white, $white, lighten($info, 7%), lighten($info, 7%), $primary);
}

.btn-thin {
	padding: 10px 45px !important;
}

.btn-slim {
	padding: 13px 45px !important;
}

.btn-wide {
	min-width: 180px;
}

.btn-chip {
	padding: 6px 12px !important;
	font-size: 13px !important;
	border: 1px solid #ccc;
}

.btn-square {
	min-width: 50px;
	padding: 13px 13px !important;
	border-radius: $card-border-radius !important;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.input-group
	> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(
		.invalid-feedback
	) {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.btn-selected {
	color: #263947;
	background-color: #faff5d;
	border-color: #faff5d;
}

.btn-sm {
	padding: 12px 30px !important;
	font-size: 11px;
}

.btn-blank {
	padding: 0 !important;
	background-color: transparent;
	margin: 0;
	height: auto;
	outline: none !important;
	box-shadow: none !important;
	border: 1px solid transparent;
}

.btn:disabled {
	cursor: not-allowed;
	pointer-events: all;
}

.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #263847;
	border-color: #263847;
}

.list-group-horizontal .list-group-item:first-child {
	border-bottom-left-radius: 2rem !important;
	border-top-left-radius: 2rem !important;
}

.list-group-horizontal .list-group-item:last-child {
	border-top-right-radius: 2rem !important;
	border-bottom-right-radius: 2rem !important;
}

@include media-breakpoint-up(md) {
	.btn {
		@include button-size($btn-padding-y-md, $btn-padding-x-md, $btn-font-size-md, $btn-border-radius);
	}
}

@include media-breakpoint-up(xl) {
	.btn {
		@include button-size($btn-padding-y-xl, $btn-padding-x-xl, $btn-font-size-xl, $btn-border-radius);
	}

	.btn-wide {
		min-width: 200px;
	}
}

.btn-group-sm-x > .btn {
	// @include button-size($btn-padding-y-sm * 1.1, $btn-padding-x-sm * 1.5, $btn-font-size-sm, $btn-border-radius-sm);
	padding: $btn-padding-y-sm * 1.4 $btn-padding-x-sm * 2 !important;
	@include font-size($btn-font-size-sm);
	@include border-radius($btn-border-radius-sm, 0);
}

// New Button Styles
$default_btn_colors: (
	'background': #6d889b,
	'text': #fff,
);
$tab_btn_colors: (
	'background': #dfe7ec,
	'text': #263846,
);
$primary_btn_colors: (
	'background': $primary,
	'text': #fff,
);
$success_btn_colors: (
	'background': #0da80a,
	'text': #fff,
);
$danger_btn_colors: (
	'background': #d83514,
	'text': #fff,
);

@mixin btn-styled {
	display: flex;
	align-items: center;
	border-radius: 8px;
	padding: 10px 12px;
	font-family: 'Inter', sans-serif;
	font-size: 16px;
	line-height: 19px;
	border: unset;
	cursor: pointer;
	white-space: nowrap;
	background-color: map-get($default_btn_colors, 'background');
	color: map-get($default_btn_colors, 'text');

	&:hover {
		color: #fff;
		background-color: #576e7d;
	}

	> .icon-right {
		margin-right: 0;
		margin-left: 8px;
	}
}

@mixin btn-styled--primary {
	background-color: map-get($primary_btn_colors, 'background');
	color: map-get($primary_btn_colors, 'text');
	&:hover {
		color: #fff;
		background-color: #576e7d;
	}
}

@mixin btn-styled--secondary {
	background-color: transparent;
	border: #6d889b 1px solid;
	color: #6d889b;

	&:hover {
		border-color: #576e7d;
		color: #576e7d;
		background-color: transparent;
	}
}

@mixin btn-styled--success {
	background-color: map-get($success_btn_colors, 'background');
	color: map-get($success_btn_colors, 'text');
	&:hover {
		color: map-get($success_btn_colors, 'text');
		background-color: #0a8408;
	}
}

@mixin btn-styled--danger {
	background-color: map-get($danger_btn_colors, 'background');
	color: map-get($danger_btn_colors, 'text');
	&:hover {
		color: #fff;
		background-color: map-get($danger_btn_colors, 'background');
	}
}

@mixin btn-styled--white-icon($color: #fff) {
	svg-icon,
	svg-icon > svg {
		stroke: $color;
		fill: $color;
	}
}

.btn-styled {
	@include btn-styled;

	&.btn-primary {
		@include btn-styled--primary;
	}

	&.btn-secondary &.light-btn {
		@include btn-styled--secondary;
	}

	&--secondary {
		@include btn-styled;
		@include btn-styled--secondary;
		&.icon {
			@include btn-styled--white-icon($secondary);
		}
	}

	&--primary {
		@include btn-styled;
		@include btn-styled--primary;
	}

	&.btn-success {
		@include btn-styled--success;
	}

	&--success {
		@include btn-styled;
		@include btn-styled--success;
	}

	&.btn-danger {
		@include btn-styled--danger;
	}

	&--danger {
		@include btn-styled;
		@include btn-styled--danger;
	}

	&--white-icon {
		@include btn-styled--white-icon;
	}

	&.btn-sm {
		padding: 8px 12px !important;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		height: 40px;
		vertical-align: text-bottom;
	}
	&.btn-round {
		width: 32px;
		height: 32px;
		border-radius: 32px;
		padding: 0 8px;
		line-height: 14px;
		> img,
		svg-icon,
		svg {
			margin-right: 0;
		}
		&.--sm {
			width: 28px;
			height: 28px;
			border-radius: 28px;
			padding: 0 6px;
		}
	}

	&.btn-primary {
		@include btn-styled--primary;
	}

	&.btn-success {
		@include btn-styled--success;
	}

	&.btn-danger {
		@include btn-styled--danger;
	}

	&.btn-tab {
		border-radius: 6px;
		background-color: transparent;
		color: #8a8a8a;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		height: 24px;
		padding: 4px 15px !important;
		display: inline-block;
		transition: background-color 0.3s ease-out;
		&.active {
			color: map-get($tab_btn_colors, 'text');
			background-color: map-get($tab_btn_colors, 'background');
		}
		&.--large {
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
		}
	}

	i {
		font-size: 11px;
	}

	> img,
	svg-icon,
	svg,
	i {
		margin-right: 8px;
		width: 16px;
		height: 16px;
		vertical-align: initial;
		&.--suffix {
			vertical-align: baseline;
			margin-left: 8px;
			margin-right: 0;
		}
		&.--standalone {
			vertical-align: baseline;
			margin-right: 0;
		}
	}

	&.light-btn {
		background-color: white;
		border: #6d889b 1px solid;
		color: #6d889b;
	}

	&.disabled {
		background-color: #a5b6c1;
		cursor: not-allowed;
	}

	&:disabled {
		opacity: 0.75;
		cursor: not-allowed;
	}

	&.loading {
		opacity: 0.75;
		cursor: not-allowed;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			width: 20%;
			height: 3px;
			left: 0;
			bottom: 0;
			border-radius: 8px;
			background-size: 300% 300%;
			background-image: linear-gradient(-45deg, yellow 0%, yellow 25%, yellow 51%, #ff357f 100%);
			animation: glow-color 3s normal infinite;
			animation-direction: alternate;
		}
	}
}

@keyframes glow-color {
	0% {
		background-position: 0% 50%;
		left: 10%;
	}
	50% {
		background-position: 100% 50%;
		left: 40%;
	}
	100% {
		background-position: 0% 50%;
		left: 70%;
	}
}
